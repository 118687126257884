<template>
    <v-card flat v-if="product && product.id > 0">
      <v-container class="fill-height" fluid>
        <v-row dense>
          <v-col cols="12">
            <v-card flat
              height="100%"
              class="flexcard"
            >
              <v-card-text class="text-center pa-0">
                <router-link :to="`store/${product.urlTag}`">
                  <image-viewer
                    :images="imageLibrary.images"
                    :noLibrary="true"
                  ></image-viewer>
                </router-link>
                <router-link :to="`store/${product.urlTag}`">
                  <div class="text-subtitle-2 font-weight-bold">{{ product.title }}</div>
                  <div class="subheading trimmed">{{descriptionText}}</div>
                </router-link>
                <div class="text-subtitle-2" v-if="currentSelection.options.length > 0 && currentSelection.hasRange">{{currentSelection.priceRange[0] | usDollars}} - {{currentSelection.priceRange[1] | usDollars}}</div>
                <div class="text-subtitle-2" v-else-if="currentSelection.displayPrice">{{currentSelection.displayPrice | usDollars}}</div>
                <div class="text-subtitle-2" v-else-if="currentSelection.displayPrice < 0">Coming Soon</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import Product from '@/classes/Product'
import CartItem from '@/classes/CartItem'
import * as actions from '@/store/ActionTypes'
import PlayerProfile from '@/classes/PlayerProfile'
import max from 'lodash.max'

export default {
  props: ['product'],
  data () {
    return {
      loading: false,
      window: 0,
      manual: false,
      validPlayer: false,
      searchR: null,
      cartDialog: false,
      selected: [],
      quantity: 1,
      shippingInfo: {
        delivery: 'none',
        name: null,
        phone: null,
        email: null,
        address: null,
        address2: null,
        city: null,
        state: null,
        zip: null
      },
      validShipping: false
    }
  },
  computed: {
    ...mapGetters(['color1IsDark', 'color2IsDark']),
    currentSelection () {
      return this.product
    },
    disabled () {
      if (!this.product.published) return true
      console.log('here')
      switch (this.window) {
        case 0:
          return !(this.validSelection && (this.currentSelection.contactReq !== 'name' || this.shippingInfo.name))
        case 1:
          return !this.validPlayer
        case 2:
          return !this.validShipping
      }
      return true
    },
    validSelection () {
      const c = this.currentSelection
      return c && c.options.length === 0 && (c.price !== -13 || c.altAmount)
    },
    moreText () {
      if (this.product.moreInfoBtn) {
        const splt = this.product.moreInfoBtn.split('|')
        return this.product.moreInfoOpen ? splt.length > 1 ? splt[1] : splt[0] : splt[0]
      }
      return this.product.moreInfoOpen ? 'Less' : 'More'
    },
    imageLibrary () {
      return this.product.jsonProps && this.product.jsonProps.imageLibrary
    },
    player () {
      return this.product.player
    },
    cartItem () {
      const item = new CartItem()
      item.newProduct(this.product)
      return item
    },
    iFrame () {
      return window.location.href.includes('iframe=true')
    },
    requiredFields () {
      if (this.currentSelection && ['contact', 'ship'].includes(this.currentSelection.contactReq)) {
        const a = ['name', 'phone', 'email']
        this.currentSelection.contactReq === 'ship' && a.push('fullAddress')
        return a
      }
      return false
    },
    quantityOptions () {
      return this.currentSelection ? [...Array(this.currentSelection.iLimit).keys()].map(x => ++x) : []
    },
    description () {
      return this.product.description
    },
    descriptionText () {
      var span = document.createElement('span')
      span.innerHTML = this.description
      var children = span.querySelectorAll('*')
      for (var i = 0; i < children.length; i++) {
        if (children[i].textContent) {
          children[i].textContent += ' '
        } else {
          children[i].innerText += ' '
        }
      }
      return [span.textContent || span.innerText].toString().replace(/ +/g, ' ')
    }
  },
  methods: {
    getDisplayProperty (prop) {
      const options = [...this.selected].reverse()
      const option = options.find(opt => {
        const p = opt[prop]
        if (p) {
          if (Array.isArray(p)) {
            return p.length > 0
          }
          return true
        }
        return false
      })
      return option ? option[prop] : null
    },
    getOptionText (o) {
      return o.title || (o.price > 0 ? `$${o.price}` : 'Other')
    },
    onToggle (o) {
      const myI = this.selected.findIndex(f => f.id === o.id)
      const l = this.selected.length
      const d = l - (myI + 1)
      if (d > 0) {
        this.selected.splice(myI + 1, d)
      }

      if (o.selectedOption) {
        this.selected.push(o.selectedOption)
      }
    },
    noGoToCart () {
      this.cartDialog = false
      this.$emit('add-another')
    },
    reset (manual) {
      this.validPlayer = false
      this.searchR = null
      this.manual = manual
      if (this.currentSelection && this.currentSelection.altAmount) {
        this.currentSelection.altAmount = null
      }
      const p = this.product
      p.player = new PlayerProfile()
      p.selected = -1
      this.selected = [p]
      if (p.options.length === 1) {
        p.selected = 0
        this.selected.push(p.options[0])
      }
      this.shippingInfo = {
        delivery: this.product.delivery,
        name: null,
        phone: null,
        email: null,
        address: null,
        address2: null,
        city: null,
        state: null,
        zip: null
      }
      this.validShipping = false
    },
    getProduct () {
      this.loading = true
      this.$VBL.store.productGet(this.tag)
        .then(r => {
          this.product = new Product(r.data)
          this.reset()
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
        })
    },
    actionClick () {
      const c = this.currentSelection
      if (this.window === 0 && c.jsonProps.requirements.player) {
        if (!c.player) {
          c.player = new PlayerProfile()
        }
        this.window = 1
      } else if (this.window === 0 && this.requiredFields) {
        this.window = 2
      } else {
        if (!this.disabled) {
          this.addToCartClick()
        } else {
          this.showErrors()
        }
      }
    },
    showErrors () {
      console.log('show errors')
    },
    addToCartClick () {
      const item = new CartItem()
      const extra = {
        shippingInfo: this.shippingInfo,
        linkId: this.linkId
      }
      item.newProduct(this.product, extra)
      item.quantity = this.quantity
      this.$store.dispatch(actions.ADD_CART_ITEM, item)
      this.reset()
      this.window = 0
      this.$emit('added-to-cart')
      if (this.addOn) return
      if (!this.iFrame) {
        this.cartDialog = true
      } else {
        this.$router.push({ name: 'checkout' })
      }
    },
    onPlayerSelected (val) {
      this.searchR = val
      this.player.setProfile(val)
      this.validPlayer = true
    },
    formatPrice (opt) {
      const a = this.currentSelection.altAmount
      this.currentSelection.altAmount = (isNaN(a) || +a === 0) ? null : parseFloat(Math.abs(a)).toFixed(2)
    }
  },
  watch: {
    window: function (val) {
      if (val === 0) this.reset()
    },
    'quantityOptions.length': function (v) {
      if (this.quantity && this.quantity > max(this.quantityOptions)) {
        this.quantity = max(this.quantityOptions)
      }
    },
    currentSelection: function (v) {
      this.shippingInfo.delivery = v.delivery
    }
  },
  components: {
    ImageViewer: () => import('@/components/Utils/ImageLibraryViewer.vue')
  }
}
// $emit('selected', product)
</script>

<style scoped>
.outlined-card {
  border: 2px solid var(--v-color3-base) !important
}
.trimmed {
   overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2;
   -webkit-box-orient: vertical;
}
a {
  text-decoration: none;
}
</style>
